<template>
  <v-dialog v-model="dialog" max-width="650px" @click:outside="is_sms_send=false">
    <template v-slot:activator="{ on }">
      <v-btn style="margin: 0" color="primary" v-on="on" block> {{ $t('Haz clic aquí para confirmar') }}</v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="headline"> {{ $t('Regístrate') }} </span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col class="pt-0 pb-0">
              <v-text-field v-model="user.nombre" :label="$t('Nombre')" :rules="name_rules" required/>
            </v-col>
            <v-col class="pt-0 pb-0">
              <v-text-field v-model="user.apellidos" :label="$t('Apellidos')" :rules="surname_rules" required/>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pt-0 pb-0">
              <v-text-field v-model="user.email" label="Email" :rules="email_rules" required/>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pt-0 pb-0">
              <v-text-field v-model="user.telefono" :label="$t('Teléfono')" :rules="phone_rules" required />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pt-0 pb-0">
              <v-select
                  v-model="user.sexo"
                  :items="sexos_items"
                  item-text="text"
                  item-value="value"
                  :label="$t('Sexo')"
                  :rules="sexo_rules"
                  required
              />
            </v-col>
          </v-row>
        </v-form>

        <v-card-actions>
          <v-row justify="center" align="center" align-content="center">
            <div v-if="is_sms_send" class="d-flex align-content-center align-center">
              <v-col>
                <v-text-field style="width: 10vh" v-model="sms_code" :label="$t('Código SMS')" required/>
              </v-col>
              <v-col>
                <v-btn text @click="register">{{ $t('Confirmar') }}</v-btn>
              </v-col>
            </div>
            <v-col class="d-flex justify-end">
              <v-btn :disabled="!valid" text @click="handle_sms_code">{{ $t('Continuar') }}</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>


<script>
export default {
  name: 'Registro',
  data(){
    return {
      is_sms_send: false,
      dialog: false,
      valid: false,
      user: {
        nombre: "",
        apellidos: "",
        email: "",
        password: "",
        telefono: "",
        ciudad: null,
        sexo: null,
        origen: 'w'
      },
      sms_code: null,

      cities_items: [],
      sexos_items: [
        { value: 'f', text: this.$t('Mujer') },
        { value: 'm', text: this.$t('Hombre') }
      ],

      name_rules: [
        v => !!v || this.$t('El nombre es obligatorio'),
        v => v.length >= 3 || this.$t('El nombre debe tener al menos 3 caracteres')
      ],
      surname_rules: [
        v => !!v || this.$t('Los apellidos son obligatorios'),
        v => v.length >= 3 || this.$t('Los apellidos deben tener al menos 3 caracteres')
      ],
      email_rules: [
        v => !!v || this.$t('El email es obligatorio'),
        v => /.+@.+/.test(v) || this.$t('El email debe ser válido')
      ],
      phone_rules: [
        phone => !!phone || this.$t('El teléfono es obligatorio'),
        phone => phone.length >= 9 || this.$t('El número de móvil debe tener al menos 9 dígitos'),
        phone => !isNaN(phone) || this.$t('El número de móvil debe tener solo cifras')
      ],
      sexo_rules: [
        v => !!v || this.$t('El sexo es obligatorio')
      ]
    }
  },

  methods: {
    async register() {
      if (this.$refs.form.validate()) {
        if (this.sms_code != this.$store.state.sms_code) {
          this.$notify({
          title: this.$t('Error'),
          text: this.$('Se ha producido un error'),
          style: 'error'
          })
          return
        }
        this.dialog = false
        this.$emit('save_reserva', this.user)
      }
    },
    async get_cities(){
      this.cities_items = await this.$store.dispatch('common/get_cities', {
        url: window.location.hostname
      })
    },

    async handle_sms_code () {
      if (!localStorage.getItem('timeStamp')) {
        localStorage.setItem('timeStamp', new Date().getTime().toString())
      }else{
        localStorage.removeItem('timeStamp')
        localStorage.setItem('timeStamp', new Date().getTime().toString())
      }


      let code = Math.floor(1000 + Math.random() * 9000)
      this.$store.commit('set_sms_code', code)



      setInterval(() =>{
        let now = new Date().getTime()
        let timeStamp = localStorage.getItem('timeStamp')
        // 5 minutes = 300000
        if(now - timeStamp > 300000){
          this.valid = true
        }
      }, 1000)

      try {
        await this.$store.dispatch('common/send_sms_code', {
          url: window.location.hostname,
          phone: this.user.telefono,
          email: this.user.email,
          code: code,
          locale: this.$i18n.locale
        })

        this.valid = false
        this.is_sms_send = true

        this.$notify({
          title: this.$t('Atención'),
          text: this.$t('Te hemos enviado un código SMS para verificar tu cuenta')
        })
      } catch (e) {
        this.$notify({
          title: this.$t('Error'),
          text: this.$t('Se ha producido un error al realizar la reserva'),
          style: 'error'
        })
      }

    }
  },

  mounted() {
    this.get_cities()
    if(this.centro){
      this.user.ciudad = this.centro.city.id
    }
  },

  computed: {
    centro(){
      return this.$store.state.centro.centro
    }
  }
}
</script>

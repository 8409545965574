<template>
  <div>
    <v-row>
      <v-col>
        <h1 class="text-center">{{ $t('Confirmar reserva') }}</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col
          v-if="reserva_done"
          cols="12"
          class="pb-0"
      >
        <v-alert
            type="primary"
            icon="mdi-alert-decagram"
        >
          {{ $t('Reserva realizada correctamente') }}
        </v-alert>

        <h1>{{ $t('Te recomendamos...') }}</h1>
        <v-img v-if="url === PARAMETERS.URL_SPAIN" src='../assets/informacion-basica.jpg' />
        <v-img v-else src='../assets/informacion-basica-new.jpg' />
      </v-col>


      <v-col
          v-if="!reserva_done"
          cols="12"
          class="pb-0"
      >
        <v-alert
            type="secondary"
            icon="mdi-alert-decagram"
        >
          {{ $t('¡Atención! Aún no has terminado el proceso de reserva, debes confirmarla para completarlo') }}.
        </v-alert>
      </v-col>

      <v-col
          v-if="!reserva_done"
          cols="12"
          class="pb-0"
      >
        <Registro @save_reserva="save_reserva_and_create_crm_user"/>
      </v-col>
    </v-row>

    <v-row v-if="centro && reserva">
      <v-col sm="12" md="6">
        <div class="titulo">{{ $t('Servicio') }}</div>
        <div class="descripcion">
          {{ reserva.descripcion }}
        </div>

        <div class="titulo">{{ $t('Fecha') }}</div>
        <div class="descripcion">
          {{ reserva.fecha | moment('DD/MM/YYYY') }} {{ reserva.hora }}
        </div>

        <v-row>
          <v-col cols="6">
            <div class="titulo">{{ $t('Dirección') }}</div>
            <div class="descripcion">
              {{ centro.direccion }}
              <br>
              {{ centro.codigo_postal }} {{ centro.ciudad }}
            </div>
          </v-col>
          <v-col cols="6">
            <div class="titulo">{{ $t('Teléfono') }}:</div>
            <div class="descripcion">
              {{ centro.telefono_whats_app }}
            </div>
          </v-col>
        </v-row>

        <div class="titulo">{{ $t('Total') }}</div>
        <div class="descripcion">
          <div>
            <span style="text-decoration: line-through; color:darkred ">{{ reserva.precioReserva | money }}</span> <br>
            <span style="font-weight: bold">{{ total | money }}</span>
          </div>
        </div>
        <v-row class="mt-2">
          <v-col>
            <div class="float-end">
              <h1>
                {{ $t('Condiciones') }}:
              </h1>
              <h3 class="pb-1">
                {{ $t('Esta promoción está reservada únicamente para nuevos clientes') }}.
              </h3>

              <h3 class="pb-1">
                {{ $t('Sólo se realizará una única sesión por persona') }}.
              </h3>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col sm="12" md="6">
        <h2>{{ centro.descripcion }}</h2>
        <v-img class="imagen_centro" :src="centro.imagen_principal_absolute_path"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import show_toolbar_mixin from "@/mixins/show_toolbar_mixin";
import store from "@/store";
import Registro from "@/components/Registro.vue";
import PARAMETERS from '@/config/parameters'
import { locale } from '@/plugins/vue-i18n'

export default {
  name: 'ConfirmarReserva',
  components: {Registro},
  mixins: [show_toolbar_mixin],

  data() {
    return {
      reserva_done: false,
      descuento_online: 0,
      url: '',
      PARAMETERS: PARAMETERS
    }
  },

  mounted() {
    this.url = window.location.hostname
  },

  methods: {
    locale() {
      return locale
    },
    async save_reserva_and_create_crm_user(user) {
      try {
        const { utm_source, utm_medium, utm_campaign } = this.get_utm_params()
        let data = {...this.reserva}
        data.cliente = user
        data.descuento = this.descuento_online
        data.precioReserva = parseFloat(this.total)
        data.locale = this.locale()
        data.hostname = window.location.hostname
        this.$store.commit('reserva/set_sexo', user.sexo)
        if (utm_source && utm_medium && utm_campaign) {
          data.utm_params = { utm_source, utm_medium, utm_campaign }
        }
        await this.$store.dispatch('reserva/save_reserva', data)
        this.$store.commit('common/set_show_toolbar', false)
        this.$notify({
          title: this.$t('Atención'),
          text: this.$t('Reserva realizada correctamente')
        })
        this.reserva_done = true
        this.$store.commit('set_reserva_confirmada', true)
      } catch (err) {
        this.$notify({
          title: this.$t('Error'),
          text: this.$('Se ha producido un error al realizar la reserva'),
          style: 'error'
        })
      }
    },

    go_to_reservas() {
      this.$router.push({name: "reservas", query: {next: 'centros'}, replace: true})
    },

    logout() {
      this.$store.dispatch('auth/logout')
      this.$router.replace('/')
    },

    get_utm_params () {
      const utm_source = this.$route.query.utm_source
      const utm_medium = this.$route.query.utm_medium
      const utm_campaign = this.$route.query.utm_campaign
      return { utm_source, utm_medium, utm_campaign }
    }
  },

  computed: {
    centro() {
      return this.$store.state.centro.centro
    },
    reserva() {
      return this.$store.state.reserva
    },
    total() {
      let precio = this.reserva.precioReserva - (this.descuento_online * this.reserva.precioReserva / 100)
      precio -= 10
      precio = Math.max(0, precio)
      return precio.toFixed(2)
    }
  }
}
</script>

<style lang="less" scoped>
.titulo {
  padding: 0.2em 1em;
  color: darkgray;
  font-size: 1.1em;
}

.descripcion {
  padding: 0 3em;
  color: var(--v-secondary-base);
  font-size: 1.2em;
}
</style>
